import { Component, Vue } from 'vue-property-decorator'

//components
import Navbar from '@/components/navbar/Navbar.vue'
import ListPanel from '@/components/listPanel/ListPanel.vue'
import ListGroups from '@/components/listGroups/ListGroups.vue'
import ListMenuOption from '@/components/listMenuOption/ListMenuOption.vue'
import PrivateContent from '@/components/privateContent/PrivateContent.vue'
import HistoryProcess from '@/components/historyProcess/HistoryProcess.vue'

const npUser = 'user';
const npPermission = 'permission';
//Vuex
import { Action, Getter } from 'vuex-class';

//classes
import { Alert } from '@/services/notification'

//models
import { User } from '@/molds/User'
import { Form, Input, Option } from '@/components/wrappers/molds/Form';
import { PermissionProfile } from '@/store/modules/permission/permission'
import { WolfApiData } from '@/components/wrappers/molds/TableOptions'

//wrappers
import {
	Container,
	Box,
	WTabs,
	WColumns,
	WColumn,
	WButton,
	WHr,
	WForm,
	WSubtitle,
	Loader,
} from '@/components/wrappers';

@Component({
	components: {
		Navbar,
		Container,
		Box,
		ListPanel,
		WTabs,
		WColumns,
		WColumn,
		ListMenuOption,
		WButton,
		WHr,
		ListGroups,
		PrivateContent,
		HistoryProcess,
		WForm,
		WSubtitle,
		Loader
	}
})
export default class UserClass extends Vue {
	//gets
	@Action('fetchUser', { namespace: npUser }) fetchUser: any;
	//puts
	@Action('updateUser', { namespace: npUser }) updateUser: any;
	@Action('updatePasswdUser', { namespace: npUser }) updatePasswdUser: any;
	@Action('updateStatusUser', { namespace: npUser }) updateStatusUser: any;
	@Action('updatePermissionUser', { namespace: npUser }) updatePermissionUser: any;
	//getters
	@Getter('user', { namespace: npUser }) user!: User;
	@Getter('users', { namespace: npUser }) users!: any;
	@Getter('error', { namespace: npUser }) error!: any;
	//permission
	@Action('fetchPermissionProfiles', { namespace: npPermission }) fetchPermissionProfiles!: any;
	@Getter('permissionProfiles', { namespace: npPermission }) permissionProfiles!: WolfApiData;
	@Getter('error', { namespace: npPermission }) errorPermission!: any;

	alert = new Alert(this.$store)
	idUser!: string
	loaderStatus = true
	loaderUpdateUser = true
	loaderPasswd = true
	isDisabled = true
	isPermissionProfile = true
	isLoading = true 
	isTab3 = false

	mounted(): void {
		document.title = 'Dados do Usuário'
		this.initPage()
	}

	initPage(){
		if (!isNaN(parseInt(this.$route.params.id))) {
			this.idUser = this.$route.params.id
			this.dispatchUser()
		} else {
			this.$router.replace("/404")
		}
	}

	tabChange(index: number): void {
		//verifica se tab3 está visivel
		if(index == 3){
			if(!this.isTab3){
				this.dispatchPermissionProfiles()
				this.isTab3 = true
			}
		}
	}

	async dispatchUser(): Promise<void> {
		this.isLoading = true
		
		await this.fetchUser(this.idUser)
		
		if (this.error) {
			this.alert.apiMessage("Não foi possível carregar os dados do usuário.", this.error, "danger")
		}else{
			await this.dispatchPermissionProfiles()
		}
		this.loaderUpdateUser = false
		this.loaderStatus = false
		this.loaderPasswd = false
		this.isLoading = false
	}

	async dispatchPermissionProfiles(): Promise<void> {	
		this.isPermissionProfile = true
		if(this.user != null && this.user.idPermission != null){
			await this.fetchPermissionProfiles("?_limit=100")
			if (this.errorPermission) {
				this.alert.apiMessage("Não foi possível carregar o perfil de permissão do usuário.", this.errorPermission, "danger")
			}
		}
		this.isPermissionProfile = false
	}

	async dispatchUpdateProfileUser(user: User): Promise<void> {
		this.isPermissionProfile = true

		const data = {
			permission: {idPermission: user.idPermission},
			idUser: this.user.idUser
		}

		await this.updatePermissionUser(data)
		
		if(this.errorPermission){
			this.alert.apiMessage(`Não foi possível atualizar perfil de permissão do usuário.`, this.errorPermission, "danger")
		}else{
			this.alert.success("Perfil de permissão atualizado com sucesso!")
		}

		this.isPermissionProfile = false
	}

	async dispatchUpdateUser(user: User): Promise<void> {
		this.loaderUpdateUser = true
		
		const onUpdate = { id: this.idUser, user: user }

		await this.updateUser(onUpdate)

		if (this.error) {
			this.alert.apiMessage("Não foi possível atualizar o usuário.", this.error, "danger")
		} else {
			this.alert.success('Usuário atualizado com sucesso!')
			this.editUser()
			await this.dispatchUser()
		}
		this.loaderUpdateUser = false
		
	}

	async dispatchUpdateStatus(user: User): Promise<void> {
		this.loaderUpdateUser = true;
		const onUpdate = { id: this.idUser, user: user }

		await this.updateStatusUser(onUpdate)

		if (this.error) {
			this.alert.apiMessage("Não foi possível atualizar status.", this.error, "danger")
		} else {
			await this.dispatchUser()
			this.alert.success('status atualizado com sucesso!')
		}
		this.loaderUpdateUser = false;
	}

	async updatePassword(passwds: any): Promise<void> {
		if (passwds.passwd != passwds.passwdConfirm) {
			this.alert.warning("Nova senha e confirmar senha não são iguais")
		} else {
			const data = {
				idUser: this.user.idUser,
				send: { password: passwds.passwd }
			}
			this.loaderPasswd = true
			await this.updatePasswdUser(data)

			if (this.error) {
				this.alert.apiMessage("Não foi possível atualizar senha.", this.error, "danger")
			} else {
				this.alert.success("Senha atualizada com sucesso!")
			}
			this.loaderPasswd = false
		}
	}

	editUser(): void {
		this.isDisabled = !this.isDisabled
	}

	updateStatus(data: User): void {		
		if (this.user.status != data.status) {
			this.dispatchUpdateStatus(data)
		} else {
			this.alert.success('status atualizado com sucesso!')
		}
	}

	get optionsProfilePerm(): Option[] {
		let profile!: PermissionProfile
		const options: Option[] = []
		if(this.isPermissionProfile == false && this.permissionProfiles.list.length > 0){
			for (let i = 0; i < this.permissionProfiles.list.length; i++) {
				profile = this.permissionProfiles.list[i]
				options.push({
					label: profile.name,
					value: profile.idPermission
				})
			}
		}		
		return options
	}

	get formUser(): Form {
		return {
			flex: false,
			indent: true,
			inputsPadding: '10px',
			grid: this.grid
		}
	}

	get formStatus(): Form {
		return {
			flex: false,
			indent: true,
			grid: [
				[
					{
						fieldName: "status",
						label: "Status",
						type: "select",
						maxlength: 11,
						value: `${this.user.status}`,
						options: [
							{
								label: "Desativado",
								value: "0"
							},
							{
								label: "Cadastrado",
								value: "1"
							},
							{
								label: "Pendente de Autorização",
								value: "2"
							},
							{
								label: "Ativo",
								value: "3"
							},
							{
								label: "Afastado",
								value: "4"
							},
							{
								label: "Desativado (temporariamente)",
								value: "5"
							},
						]
					},
				]
			]
		}
	}

	get grid(): Input[][] {
		if(this.loaderUpdateUser){
			return []
		} 
		return [
			[
				{
					fieldName: "name",
					label: "Nome",
					type: "text",
					maxlength: 50,
					placeholder: "nome",
					required: true,
					value: this.user.name,
					disabled: this.isDisabled
				},
				{
					fieldName: "login",
					label: "E-mail",
					type: "email",
					maxlength: 50,
					placeholder: "email",
					required: true,
					value: this.user.login,
					disabled: this.isDisabled
				},						
			],
			[
				{
					fieldName: "document",
					label: "Documento",
					type: "document",
					maxlength: 12,
					placeholder:"documento",
					required: true,
					value: this.user.document,
					disabled: this.isDisabled
				},	
				{
					fieldName: "birthDate",
					label: "Nascimento",
					type: "date",
					maxlength: 10,
					required: true,
					value: this.user.birthDate,
					disabled: this.isDisabled
				},					
			],
		]
	}

	get formPasswd(): Form {
		return {
			flex: false,
			indent: true,
			inputsPadding: '10px',
			grid: [
				[
					{
						fieldName: "passwd",
						label: "Nova senha",
						placeholder: "nova senha",
						type: "password",
						maxlength: 100,
						required: true,
					},
					{
						fieldName: "passwdConfirm",
						label: "Confirmar senha",
						placeholder: "confirmar senha",
						type: "password",
						maxlength: 100,
						required: true,
					},
				],
			]
		}
	}

	get formProfilePerm(): Form {
		return {
			flex: false,
			indent: true,
			grid: [
				[
					{
						fieldName: "idPermission",
						label: "Perfil de Permissão",
						type: "select",
						maxlength: 11,
						value: this.user.idPermission,
						options: this.optionsProfilePerm
					},
				]
			]
		}
	}

}
